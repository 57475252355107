/* StatementOfAccount.css */
.printable-container {
  padding: 20px;
  background-color: white;
}

.printable-header {
  margin-bottom: 20px;
}

.printable-filter {
  margin-bottom: 20px;
}

.printable-table {
  margin-bottom: 20px;
}

.printable-total {
  font-size: 16px;
  font-weight: bold;
}

/* General print styles */
@media print {
  /* Hide elements not relevant for printing */
  body * {
    visibility: hidden;
  }

  /* Show only the statement container */
  .printable-container, .printable-container * {
    visibility: visible;
  }

  /* Remove any margin and padding for printing */
  .printable-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  /* Hide any buttons */
  .ant-btn {
    display: none;
  }

  /* Remove margins from the header and filter sections */
  .printable-header, .printable-filter {
    margin: 0;
  }
}
